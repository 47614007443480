const infuraKey =
  "f3a1272c002a4cf883838759883fafa3" || process.env.VUE_APP_INFURA_KEY;
const networksInfo = [
  {
    chainId: 1,
    name: "mainnet",
    scan: "https://etherscan.io",
    rpcUrl: `https://mainnet.infura.io/v3/${infuraKey}`,
    symbol: "ETH",
  },
  {
    name: "sepolia",
    chainId: 11155111,
    rpcUrl: `https://sepolia.infura.io/v3/${infuraKey}`,
    scan: "https://sepolia.etherscan.io",
    symbol: "ETH",
  },
  {
    chainId: 56,
    networkId: 56,
    name: "BNB Chain",
    scan: "https://bscscan.com/",
    symbol: "BNB",
  },
];

export { networksInfo };
