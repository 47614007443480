import { useApesStorageApi } from "@/api/useApesStorageApi";
import { useBapApi } from "@/api/useBapApi";
import { TokenType } from "@/config/token-types";
import { useWalletCollectionStore } from "@/store/wallet-collection";
import { useWeb3Store } from "@/store/web3";
import axios from "axios";
import { fetchPartnerLevels } from "./fetchPartnerLevels";

export const fetchPartnerTokens = async () => {
  return {};

  const wallet = useWeb3Store().collectionWallet;
  const walletCollectionStore = useWalletCollectionStore();
  let partnerTokens = {};
  if (Object.keys(walletCollectionStore.partners).length) {
    partnerTokens = walletCollectionStore.partners;
  } else {
    partnerTokens = await getPartnersCollection(wallet);
  }
  if (Object.keys(partnerTokens).length) {
    await Promise.all(
      Object.keys(partnerTokens).map(async (el) => {
        const list = partnerTokens[el];
        try {
          const levels = await fetchPartnerLevels(
            list[0].token_address,
            list.map((i) => i.id)
          );

          partnerTokens[el].map((token) => {
            const levelsInfo = levels[token.id];
            const level = levelsInfo.level;
            token.harvestInfo = {
              level,
              harvestPerDay: levelsInfo.meth_amount,
              isHarvestedToday:
                levelsInfo.harvested_today ||
                levelsInfo.harvested_today_another_wallet,
              daysLeft: levelsInfo.days_until_next_level,
            };
            return token;
          });
        } catch (error) {
          console.log(`Error getting levels info for ${el}`, error);
        }
      })
    );
  }
  return partnerTokens;
};
const getPartnersCollection = async function (wallet) {
  const [data, erc1155Tokens] = await Promise.all([
    useBapApi({ throw: true }).exec({
      url: `/mint/partners?wallet=${wallet}`,
    }),
    // ERC1155 tokens
    useApesStorageApi({ throw: true }).exec({
      url: "/wallet/partners",
      params: {
        wallet: useWeb3Store().collectionWallet,
      },
    }),
  ]);
  const tokens = data.result?.nfts || {};
  const items = { ...erc1155Tokens, ...tokens };
  if (!items) return;

  let keys = Object.keys(items);

  for (let index = 0; index < keys.length; index++) {
    const tokens = items[keys[index]];
    for (let i = 0; i < tokens.length; i++) {
      const el = tokens[i];

      let extraInfo = el.metadata;
      if (!extraInfo || !extraInfo.image) {
        try {
          const { data } = await axios.get(el.uri);
          extraInfo = {
            ...data,
            image: data.image ? data.image : data.image_url,
            traits: data.traits || data.attributes,
          };
        } catch (error) {
          extraInfo = {};
          console.log(`Error getting partner metadata`, el.symbol, el.id);
        }
      }

      if (extraInfo && extraInfo.image) {
        let isIPFS = extraInfo.image.split(":/").includes("ipfs");

        if (isIPFS) {
          let url = extraInfo.image.split(":/");
          url = "https://botbipfs.infura-ipfs.io/ipfs" + url[1];
          extraInfo.image = url;
        }
      }

      extraInfo.harvestInfo = {
        level: 1,
        harvestPerDay: 1,
        isHarvestedToday: false,
        daysLeft: 4,
      };

      tokens[i] = {
        ...el,
        ...extraInfo,
        id: el.id,
        type: TokenType.PARTNER,
      };
    }
  }

  return items;
};
